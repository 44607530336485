import { BackstageOverrides } from '@backstage/core-components';
import { BackstageOverrides as CatalogReactOverrides } from '@backstage/plugin-catalog-react';
import {
  createUnifiedTheme,
  // pageTheme as defaultPageThemes,
  // pageTheme,
  palettes,
  UnifiedThemeOptions,
  genPageTheme,
  shapes,
} from '@backstage/theme';

import { alpha } from '@material-ui/core/styles';
import { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import { AlertClassKey } from '@material-ui/lab/Alert';

// Labs types not included in overrides; https://github.com/mui/material-ui/issues/19427
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAutocomplete: AutocompleteClassKey;
  }
}

const FONT_COLOR = "#34174d"
/**
 * How to add a shape:
 *
 * 1. Get the SVG shape from figma, should be ~1400 wide, ~400 high
 *    and only the white-to-transparent mask, no colors.
 * 2. Run it through https://jakearchibald.github.io/svgomg/
 * 3. Run that through https://github.com/tigt/mini-svg-data-uri
 *    with something like https://npm.runkit.com/mini-svg-data-uri
 * 4. Wrap the output in `url("")`
 * 5. Give it a name and paste it into the `shapes` object below.
 */
const ACCENTURE_SHAPES = {
  empty: `url("")`,
}

export type ExtendedBackstageOverrides = {
  [K in keyof BackstageOverrides]: BackstageOverrides[K] & {
    styleOverrides?: BackstageOverrides[K];
  };
};
export type ExtendedBackstageReactOverrides = {
  [K in keyof CatalogReactOverrides]: CatalogReactOverrides[K] & {
    styleOverrides?: CatalogReactOverrides[K];
  };
};

const accentureThemePalette = {
  ...palettes.light,
    primary: {
      main: '#732bc1',
      light: '#9c72ca',
      dark: '#361658',
    },
    secondary: {
      main: '#FF5630',
      light: '#FFAB00',
      dark: '#6554C0',
    },
    grey: {
      50: '#c7c1d0',
      100: '#877a9a',
      200: '#746b8c',
      300: '#6d5e84',
      400: '#605079',
      500: '#53426e',
      600: '#443463',
      700: '#3f2558',
      800: '#32174d',
      900: '#240942',
    },
    error: {
      main: '#FF5630',
      light: '#FF8F73',
      dark: '#DE350B',
    },
    warning: {
      main: '#FFAB00',
      light: '#FFE380',
      dark: '#FF8B00',
    },
    success: {
      main: '#36B37E',
      light: '#79F2C0',
      dark: '#006644',
    },
    info: {
      main: '#732bc1',
      light: '#9c72ca',
      dark: '#361658',
    },
    navigation: {
      ...palettes.light.navigation,
      background: '#300a59',
      color: '#FFFFFF',
      indicator: '#8e41e0',
      navItem: {
        hoverBackground: 'rgba(116,118,121,0.6)',
      },
    },
    text: {
      primary: '#301748',
    },
    background: {
      default: '#e5e5e5',
    },
}

const components: UnifiedThemeOptions['components'] & ExtendedBackstageOverrides & ExtendedBackstageReactOverrides= {
  BackstageHeader: {
    header: {
      backgroundImage: 'unset',
      boxShadow: 'unset',
    },
    title: {
      color: "#HHHHHH",
      fontWeight: 900,
    },
    subtitle: {
      color: alpha("#HHHHHH", 0.8),
    },
    type: {
      color: alpha("#HHHHHH", 0.8),
    },
  },
  BackstageHeaderLabel: {
    label: {
      color: FONT_COLOR,
    },
    value: {
      color: alpha(FONT_COLOR, 0.8),
    },
  },
  BackstageHeaderTabs: {
    defaultTab: {
      fontSize: 'inherit',
      textTransform: 'none',
    },
  },
  BackstageOpenedDropdown: {
    icon: {
      '& path': {
        fill: '#FFFFFF',
      },
    },
  },
  BackstageTable: {
    root: {
      '&> :first-child': {
        borderBottom: '1px solid #D5D5D5',
        boxShadow: 'none',
      },
      '& th': {
        borderTop: 'none',
        textTransform: 'none !important',
      },
    },
  },
  CatalogReactUserListPicker: {
    title: {
      textTransform: 'none',
    },
  },
  MuiAlert: {
    root: {
      borderRadius: 0,
    },
    standardError: {
      color: '#FFFFFF',
      backgroundColor: accentureThemePalette.error.dark,
      '& $icon': {
        color: '#FFFFFF',
      },
    },
    standardInfo: {
      color: '#FFFFFF',
      backgroundColor: accentureThemePalette.primary.dark,
      '& $icon': {
        color: '#FFFFFF',
      },
    },
    standardSuccess: {
      color: '#FFFFFF',
      backgroundColor: accentureThemePalette.success.dark,
      '& $icon': {
        color: '#FFFFFF',
      },
    },
    standardWarning: {
      color: accentureThemePalette.grey[700],
      backgroundColor: accentureThemePalette.secondary.light,
      '& $icon': {
        color: accentureThemePalette.grey[700],
      },
    },
  },
  MuiAutocomplete: {
    root: {
      '&[aria-expanded=true]': {
        backgroundColor: '#26385A',
        color: '#FFFFFF',
      },
      '&[aria-expanded=true] path': {
        fill: '#FFFFFF',
      },
    },
  },
  MuiBackdrop: {
    root: {
      backgroundColor: 'rgba(9,30,69,0.54)',
    },
  },
  MuiButton: {
    root: {
      borderRadius: 3,
      textTransform: 'none',
    },
    contained: {
      boxShadow: 'none',
    },
  },
  MuiChip: {
    root: {
      borderRadius: 3,
      backgroundColor: accentureThemePalette.grey[50],
      color: accentureThemePalette.primary.dark,
      margin: 4,
    },
  },
  MuiSelect: {
    root: {
      '&[aria-expanded]': {
        backgroundColor: '#26385A',
        color: '#FFFFFF',
      },
    },
  },
  MuiSwitch: {
    root: {
      padding: 10,
    },
    switchBase: {
      padding: 12,
    },
    thumb: {
      backgroundColor: '#FFFFFF',
      height: 14,
      width: 14,
    },
    track: {
      borderRadius: 9,
    },
  },
  MuiTabs: {
    indicator: {
      transition: 'none',
    },
  },
  MuiTypography: {
    button: {
      textTransform: 'none',
    },
  },
}
export const accentureTheme = createUnifiedTheme({
  palette:{
    ...accentureThemePalette
  },
  fontFamily: 'Roboto, sans-serif',
  // pageTheme: pageThemesFontColorOverride,
  pageTheme: {
    home: genPageTheme({ 
      colors: ['#300a59', '#d1c6e3','#300a59'],
      shape: ACCENTURE_SHAPES.empty,
      options: {
        fontColor: '#ffffff'
      }
    }),
    documentation: genPageTheme({
      colors: ['#300a59','#d1c6e3'],
      shape: ACCENTURE_SHAPES.empty,
    }),
    tool: genPageTheme({ 
      colors: ['#300a59', '#d1c6e3'],
      shape: ACCENTURE_SHAPES.empty,
    }),
    service: genPageTheme({
      colors: ['#300a59', '#d1c6e3'],
      shape: ACCENTURE_SHAPES.empty,
    }),
    website: genPageTheme({
      colors: ['#300a59', '#d1c6e3'],
      shape: ACCENTURE_SHAPES.empty,
    }),
    library: genPageTheme({
      colors: ['#300a59', '#d1c6e3'],
      shape: ACCENTURE_SHAPES.empty,
    }),
    other: genPageTheme({ 
      colors: ['#300a59', '#d1c6e3'],
      shape: shapes.round,
    }),
    app: genPageTheme({ 
      colors: ['#300a59', '#d1c6e3'],
      shape: shapes.wave2,
    }),
    apis: genPageTheme({ 
      colors: ['#300a59', '#d1c6e3'],
      shape: shapes.wave,
    }),
  },
  defaultPageTheme: 'home',
  // htmlFontSize: 12,
  // typography: BackstageTypography;
  components,
});